<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Template de composição</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Templates de
                  <span>Composição</span>
                  de Documentos para Matrículas
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de templates
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-secondary mx-2"
                            @click.prevent="getTemplatesComposicaoInativos()"
                          >
                            <small>Exibir inativos</small>
                          </button>
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="exibeModalCriarTemplate()"
                          >
                            <small>+ Criar template</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Template</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Status</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Tipo documento</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Contrato associado</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Inscrição associada</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Curso associado</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Gateway associado</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaComposicoesCarregando">
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaTemplates.length">
                              <tr
                                v-for="(template, index) in fastSecretariaTemplates"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  <small>
                                    {{ template.nome_template }}
                                  </small>
                                </td>                                
                                <td class="align-middle text-center">
                                  <small v-if="template.ativo" class="text-success">
                                    Ativo
                                  </small>
                                  <small v-else class="text-danger">
                                    Inativo
                                  </small>
                                </td>                                
                                <td class="align-middle text-center">
                                  <small>
                                    {{ getNameTipoDocumento(template.id_assinatura_documento_tipo) }}
                                  </small>
                                </td>                                
                                <td v-if="!template.fast_assinatura_matricula_template_contrato.length" class="align-middle text-center">
                                  <small>
                                    Sem contrato associado
                                  </small>
                                </td>                                
                                <td v-else class="align-middle text-center">
                                  <small v-for="(contrato, index) in template.fast_assinatura_matricula_template_contrato" :key="index" class="align-middle text-center">
                                    {{ getNameTemplateContrato(contrato.id_contrato) }}; 
                                  </small>
                                </td>                                
                                <td v-if="!template.fast_assinatura_matricula_template_inscricao.length" class="align-middle text-center">
                                  <small>
                                    Sem inscrição associada
                                  </small>
                                </td>                                
                                <td v-else class="align-middle text-center">
                                  <small v-for="(inscricao, index) in template.fast_assinatura_matricula_template_inscricao" :key="index">
                                    {{ getNameTemplateInscricao(inscricao.id_secretaria_inscricao) }};
                                  </small>
                                </td>                                
                                <td v-if="!template.id_secretaria_curso" class="align-middle text-center">
                                  <small>
                                    Todos
                                  </small>
                                </td>                                
                                <td v-else class="align-middle text-center">
                                  <small>
                                    {{ getNameCurso(template.id_secretaria_curso) }}
                                  </small>
                                </td>                                
                                <td v-if="!template.id_assinatura_gateway" class="align-middle text-center">
                                  <small>
                                    Todos
                                  </small>
                                </td>                                
                                <td v-else class="align-middle text-center">
                                  <small>
                                    {{ getNameGateway(template.id_assinatura_gateway) }}
                                  </small>
                                </td>                                
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarTemplate(template)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  Nenhum documento cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarTemplate"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo template</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTemplate')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="nome_template">Nome do template de composição de documentos</label>
              <input
                v-model="fastSecretariaTemplate.nome_template"
                type="text"
                id="nome_template"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="gateway_assinaturas">Gateway de assinaturas</label>
              <select class="form-control" id="gateway_assinaturas" v-model="fastSecretariaTemplate.id_assinatura_gateway">
                <option value="">
                  Qualquer um
                </option>
                <option v-for="(gateway, index) in fastSecretariaGateways" :key="index" :value="gateway.id_assinatura_gateway">
                  {{ gateway.nome_gateway }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="tipos_documento">Tipo de documento</label>
              <select class="form-control" id="tipos_documento" readonly>
                <option value="">
                  Nenhum
                </option>
                <option v-for="(tipo, index) in fastSecretariaTipos" :key="index" :value="tipo.id_assinatura_documento_tipo">
                  {{ tipo.nome_assinatura_documento_tipo }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="categoria_documento">Categoria de documento</label>
              <select class="form-control" id="categoria_documento" readonly>
                <option value="">
                  Nenhum
                </option>
                <option v-for="(categoria, index) in fastSecretariaCategorias" :key="index" :value="categoria.id_assinatura_plataforma_documento_categoria">
                  {{ categoria.nome_assinatura_plataforma_documento_categoria }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="secretaria_curso">Curso</label>
              <select class="form-control" id="secretaria_curso" v-model="fastSecretariaTemplate.id_secretaria_curso">
                <option value="">
                  Todos
                </option>
                <option v-for="(curso, index) in fastSecretariaCursos" :key="index" :value="curso.id_secretaria_curso">
                  {{ curso.nome_curso }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="contrato_template">Template de contrato</label>
              <button  
              v-tooltip.bottom-start="{ content: exibeTooltip('contrato') }"
              class="btn btn-sm btn-primary ml-2" @click.prevent="adicionaTemplateContrato()">+</button>
              <button class="btn btn-sm btn-danger ml-2" @click.prevent="excluiTemplateContrato()" v-if="fastSecretariaContratosArray.length > 0">-</button>
              <select class="form-control" id="contrato_template" v-model="fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato">
                <option value="">
                  Nenhum
                </option>
                <option v-for="(contrato, index) in fastSecretariaContratos" 
                :key="index" :value="contrato.id_contrato">
                  {{ contrato.nome_contrato }}
                </option>
              </select>
            </div>
            <div v-if="fastSecretariaContratosArray.length" class="col-sm-12 col-md-12 col-lg-12">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Templates de contrato</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(template, index) in fastSecretariaContratosArray"
                    :key="index"
                  >
                    <td class="align-middle text-center">
                      {{ getNameTemplateContrato(template.id_contrato) }}
                    </td>                                
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="inscricao_template">Template de inscrição</label>
              <button  
              v-tooltip.bottom-start="{ content: exibeTooltip('inscricao') }"
              class="btn btn-sm btn-primary ml-2" @click.prevent="adicionaTemplateInscricao()">+</button>
              <button class="btn btn-sm btn-danger ml-2" @click.prevent="excluiTemplateInscricao()" v-if="fastSecretariaInscricaoArray.length > 0">-</button>
              <select class="form-control" id="inscricao_template" v-model="fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao">
                <option value="">
                  Nenhum
                </option>
                <option v-for="(inscricao, index) in fastSecretariaInscricoes" :key="index" :value="inscricao.id_secretaria_inscricao">
                  {{ inscricao.nome_template }}
                </option>
              </select>
            </div>
            <div v-if="fastSecretariaInscricaoArray.length" class="col-sm-12 col-md-12 col-lg-12">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Templates de Inscrição</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(inscricao, index) in fastSecretariaInscricaoArray"
                    :key="index"
                  >
                    <td class="align-middle text-center">
                      {{ getNameTemplateInscricao(inscricao.id_secretaria_inscricao) }}
                    </td>                                
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="aluno_signatario">Aluno é signatário </label>
              <input
              v-model="fastSecretariaTemplate.aluno_assina"
              type="checkbox"
              id="aluno_signatario"
              class="form-check-input"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="responsavel_signatario">Responsável pelo aluno é signatário </label>
              <input
                v-model="fastSecretariaTemplate.responsavel_aluno_assina"
                type="checkbox"
                id="responsavel_signatario"
                class="form-check-input"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="gateway_signatario">Administrador da conta do gateway é signatário</label>
              <input
                v-model="fastSecretariaTemplate.responsavel_gateway_assina"
                type="checkbox"
                id="gateway_signatario"
                class="form-check-input"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTemplateComposicao()"
                >
                  Criar Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarTemplate"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar template</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarTemplate')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="nome_template">Editar template de composição de documentos</label>
              <input
                v-model="fastSecretariaTemplate.nome_template"
                type="text"
                id="nome_template"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="template_ativo">Ativo </label>
              <input
                v-model="fastSecretariaTemplate.ativo"
                type="checkbox"
                id="template_ativo"
                class="form-check-input"
                @change="avisoTemplateAtivo()"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="gateway_assinaturas">Gateway de assinaturas</label>
              <select class="form-control" id="gateway_assinaturas" v-model="fastSecretariaTemplate.id_assinatura_gateway">
                <option value="">
                  Qualquer um
                </option>
                <option v-for="(gateway, index) in fastSecretariaGateways" :key="index" :value="gateway.id_assinatura_gateway">
                  {{ gateway.nome_gateway }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="secretaria_curso">Curso</label>
              <select class="form-control" id="secretaria_curso" v-model="fastSecretariaTemplate.id_secretaria_curso">
                <option value="">
                  Todos
                </option>
                <option v-for="(curso, index) in fastSecretariaCursos" :key="index" :value="curso.id_secretaria_curso">
                  {{ curso.nome_curso }}
                </option>
              </select>
            </div>
            <div v-if="fastSecretariaContratosArray.length" class="col-sm-12 col-md-12 col-lg-12 text-center my-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Templates de contrato atualmente na composição</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(template, index) in fastSecretariaContratosArray"
                    :key="index"
                  >
                    <td class="align-middle text-center">
                      {{ getNameTemplateContrato(template.id_contrato) }}
                    </td>                                
                  </tr>
                </tbody>
              </table>
              <button
                href="#"
                type="button"
                class="btn btn-secondary m-1"
                @click="exibeModalAtualizarTemplateContrato()"
              >
                <small>
                  Editar templates de contrato
                </small>
              </button>
            </div>
            <div v-if="fastSecretariaInscricaoArray.length" class="col-sm-12 col-md-12 col-lg-12 text-center my-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Templates de inscrição atualmente na composição</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(inscricao, index) in fastSecretariaInscricaoArray"
                    :key="index"
                  >
                    <td class="align-middle text-center">
                      {{ getNameTemplateInscricao(inscricao.id_secretaria_inscricao) }}
                    </td>                                
                  </tr>
                </tbody>
              </table>
              <button
                href="#"
                type="button"
                class="btn btn-secondary m-1"
                @click="exibeModalAtualizarTemplateInscricao()"
              >
                <small>
                  Editar templates de inscrição
                </small>
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center my-2">
              <button v-if="!fastSecretariaContratosArray.length"
                href="#"
                type="button"
                class="btn btn-secondary m-1"
                @click="exibeModalAtualizarTemplateContrato()"
              >
                <small>
                  Editar templates de contrato
                </small>
              </button>
              <button v-if="!fastSecretariaInscricaoArray.length"
                href="#"
                type="button"
                class="btn btn-secondary m-1"
                @click="exibeModalAtualizarTemplateInscricao()"
              >
                <small>
                  Editar templates de inscrição
                </small>
              </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="aluno_signatario">Aluno é signatário </label>
              <input
              v-model="fastSecretariaTemplate.aluno_assina"
              type="checkbox"
              id="aluno_signatario"
              class="form-check-input"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="responsavel_signatario">Responsável pelo aluno é signatário </label>
              <input
                v-model="fastSecretariaTemplate.responsavel_aluno_assina"
                type="checkbox"
                id="responsavel_signatario"
                class="form-check-input"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="gateway_signatario">Administrador da conta do gateway é signatário</label>
              <input
                v-model="fastSecretariaTemplate.responsavel_gateway_assina"
                type="checkbox"
                id="gateway_signatario"
                class="form-check-input"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="atualizaTemplate()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalAtualizarTemplateContrato"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row col-sm-12 col-md-12 p-4 text-center">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar template</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAtualizarTemplateContrato')"
            >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 my-2">
          <h6>O que seseja fazer com templates de contrato?</h6>
        </div>
        <div class="d-flex justify-content-around mx-auto mx-2">
          <button
            href="#"
            type="button"
            class="btn btn-primary mx-1"
            @click="fastSecretariaOpcaoAdicionaContrato = true, fastSecretariaOpcaoRemoveContrato = false"
          >
            <small>
              Adicionar template de contrato à composição
            </small>
          </button>
          <button
            href="#"
            type="button"
            class="btn btn-danger mx-1"
            @click="fastSecretariaOpcaoAdicionaContrato = false, fastSecretariaOpcaoRemoveContrato = true"
          >
            <small>
              Remover template de contrado da composição
            </small>
          </button>
        </div>
        <div v-if="fastSecretariaOpcaoAdicionaContrato" class="col-sm-12 col-md-12 col-lg-12 my-2">
          <label for="contrato_template">Template de contrato</label>
          <select class="form-control" id="contrato_template" v-model="fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato">
            <option v-for="(contrato, index) in fastSecretariaContratos" 
            :key="index" :value="contrato.id_contrato">
              {{ contrato.nome_contrato }}
            </option>
          </select>
          <div class="form-group my-2">
            <button
              href="#"
              type="button"
              class="btn btn-primary"
              @click="atualizaTemplateAdicionaContrato()"
            >
              Adicionar template de contrato
            </button>
          </div>
        </div>
        <div v-if="fastSecretariaContratosArray.length && fastSecretariaOpcaoRemoveContrato" class="col-sm-12 col-md-12 col-lg-12 my-2">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr class="text-center">
                <th>
                  <small
                    class="font-weight-bold"
                  >Templates de contrato</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >Ação</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(template, index) in fastSecretariaContratosArray"
                :key="index"
              >
                <td class="align-middle text-center">
                  {{ getNameTemplateContrato(template.id_contrato) }}
                </td>                                
                <td class="align-middle text-center">
                  <button
                          href="#"
                    type="button"
                    class="btn btn-danger"
                    @click="atualizaTemplateRemoveContrato(template.id_assinatura_matricula_template_contrato)"
                  >
                    Remover
                  </button>
                </td>                                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>

    <modal
      name="modalAtualizarTemplateInscricao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row col-sm-12 col-md-12 p-4 text-center">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar template</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAtualizarTemplateInscricao')"
            >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 my-2">
          <h6>O que seseja fazer com templates de inscrição?</h6>
        </div>
        <div class="d-flex justify-content-around mx-auto mx-2">
          <button
            href="#"
            type="button"
            class="btn btn-primary mx-1"
            @click="fastSecretariaOpcaoAdicionaInscricao = true, fastSecretariaOpcaoRemoveInscricao = false"
          >
            <small>
              Adicionar template de inscrição à composição
            </small>
          </button>
          <button
            href="#"
            type="button"
            class="btn btn-danger mx-1"
            @click="fastSecretariaOpcaoAdicionaInscricao = false, fastSecretariaOpcaoRemoveInscricao = true"
          >
            <small>
              Remover template de inscrição da composição
            </small>
          </button>
        </div>
        <div v-if="fastSecretariaOpcaoAdicionaInscricao" class="col-sm-12 col-md-12 col-lg-12 my-2">
          <label for="inscricao_template">Template de inscrição</label>
          <select class="form-control" id="inscricao_template" v-model="fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao">
            <option v-for="(inscricao, index) in fastSecretariaInscricoes" 
            :key="index" :value="inscricao.id_secretaria_inscricao">
              {{ inscricao.nome_template }}
            </option>
          </select>
          <div class="form-group my-2">
            <button
              href="#"
              type="button"
              class="btn btn-primary"
              @click="atualizaTemplateAdicionaInscricao()"
            >
              Adicionar template de inscrição
            </button>
          </div>
        </div>
        <div v-if="fastSecretariaInscricaoArray.length && fastSecretariaOpcaoRemoveInscricao" class="col-sm-12 col-md-12 col-lg-12 my-2">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr class="text-center">
                <th>
                  <small
                    class="font-weight-bold"
                  >Templates de Inscrição</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >Ação</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(inscricao, index) in fastSecretariaInscricaoArray"
                :key="index"
              >
                <td class="align-middle text-center">
                  {{ getNameTemplateInscricao(inscricao.id_secretaria_inscricao) }}
                </td>                                
                <td class="align-middle text-center">
                  <button
                          href="#"
                    type="button"
                    class="btn btn-danger"
                    @click="atualizaTemplateRemoveInscricao(inscricao.id_assinatura_matricula_template)"
                  >
                    Remover
                  </button>
                </td>                                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaTemplateComposicao",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Documento
      fastSecretariaComposicoesCarregando: true,
      fastSecretariaComposicoesAtivos: true,
      fastSecretariaGateways: [],
      fastSecretariaContratos: [],
      fastSecretariaInscricoes: [],
      fastSecretariaCursos: [],
      fastSecretariaTipos: [],
      fastSecretariaCategorias: [],
      fastSecretariaContratosArray: [],
      fastSecretariaInscricaoArray: [],
      fastSecretariaOpcaoAdicionaContrato: "",
      fastSecretariaOpcaoRemoveContrato: "",
      fastSecretariaOpcaoAdicionaInscricao: "",
      fastSecretariaOpcaoRemoveInscricao: "",
      fastSecretariaTemplates: [
        {
          id_assinatura_matricula_template: "",
          id_assinatura_documento_tipo: "",
          id_plataforma: "",
          nome_template: "",
          id_assinatura_gateway: "",
          ativo: "",
          aluno_assina: "",
          responsavel_aluno_assina: "",
          responsavel_gateway_assina: "",
          id_pessoa_assina: "",
          id_funcao_pessoa_assina: "",
          id_secretaria_curso: "",
          fast_assinatura_matricula_template_contrato: [
            {
              id_assinatura_matricula_template_contrato: "",
              id_assinatura_matricula_template: "",
              id_plataforma: "",
              id_contrato: "", 
            }
          ],
          fast_assinatura_matricula_template_inscricao: [
            {
              id_assinatura_matricula_template_inscricao: "",
              id_assinatura_matricula_template: "",
              id_plataforma: "",
              id_secretaria_inscricao: "",
            }
          ],
          fast_secretaria_curso: {
            Id_secretaria_curso: "",
            Nome_curso:   "",
            Detalhe_curso:   "",
            Publicado: "",
            Carga_horaria: "",
            Logo_curso: "",
            Id_plataforma: "",
            Creditos_total: "",
            Nivel: "",
            Preco: "",
            Venda_modulo: "",
            Venda_unidade_curricular: "",
            Gera_certificado: "",
            Possui_aproveitamento: "",
            Id_base_curricular: "",
            Loja_virtual: "",
            Descricao_legal: "",
          }
        }
      ],
      fastSecretariaTemplate: {
        id_assinatura_matricula_template: "",
        id_plataforma: "",
        nome_template: "",
        id_assinatura_gateway: "",
        ativo: "",
        aluno_assina: false,
        responsavel_aluno_assina: false,
        responsavel_gateway_assina: false,
        id_pessoa_assina: "",
        id_funcao_pessoa_assina: "",
        id_secretaria_curso: "",
        fast_assinatura_matricula_template_contrato: [
          {
            id_contrato: "",
          }
        ],
        fast_assinatura_matricula_template_inscricao: [
          {
            id_secretaria_inscricao: "",
          }
        ]
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Tipo documentos
            this.getTemplatesComposicao()
            this.getGatewaysAssinatura()
            this.getContratosTemplates()
            this.getInscricoesTemplates()
            this.getSecretariaTiposDocumento()
            this.getSecretariaCategoriasDocumento()
            this.getSecretariaCuros()
            this.fastSecretariaComposicoesCarregando = false;
          } else {
            this.fastSecretariaComposicoesCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.fastSecretariaComposicoesCarregando = false;
        });
    } else {
      this.fastSecretariaComposicoesCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    getTemplatesComposicao(){
      this.fastSecretariaComposicoesCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_matricula_template/lista", `id_plataforma=${this.$route.params.id_plataforma}&ativo=${this.fastSecretariaComposicoesAtivos}`).then(obj => {
        // console.log("getTemplatesComposicao", obj);
        if (obj.length) this.fastSecretariaTemplates = obj;
        this.fastSecretariaComposicoesCarregando = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaComposicoesCarregando = false
      })
    },
    getTemplatesComposicaoInativos(){
      this.fastSecretariaComposicoesCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_matricula_template/lista", `id_plataforma=${this.$route.params.id_plataforma}`).then(obj => {
        // console.log("getTemplatesComposicao", obj);
        if (obj.length) this.fastSecretariaTemplates = obj;
        this.fastSecretariaComposicoesCarregando = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaComposicoesCarregando = false
      })
    },
    getGatewaysAssinatura(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_assinatura_gateway/lista_disponiveis", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastSecretariaGateways = res
          // console.log("gateways", this.fastSecretariaGateways)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getContratosTemplates(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_secretaria_contrato/lista", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastSecretariaContratos = res
          // console.log("contratos", this.fastSecretariaContratos)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getInscricoesTemplates(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_secretaria_matricula_inscricao/lista", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastSecretariaInscricoes = res
          console.log("inscricoes", this.fastSecretariaInscricoes)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getSecretariaCuros(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastSecretariaCursos = res
          // console.log("secretaria", this.fastSecretariaCursos)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getSecretariaTiposDocumento(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_assinatura_documento_tipo/lista_todos", "")
      .then((res) => {
        if (res) {
          this.fastSecretariaTipos = res
          // console.log("tipos", this.fastSecretariaTipos)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    getSecretariaCategoriasDocumento(){
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_assinatura_plataforma_documento_categoria/lista_id", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastSecretariaCategorias = res
          // console.log("categorias", this.fastSecretariaCategorias)
        }
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.$store.state.fastCarregando = false;
      })
    },
    exibeModalCriarTemplate(){
      this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato = ''
      this.showModal('modalCriarTemplate')
    },
    adicionaTemplateContrato(){
      if(this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato){
        this.fastSecretariaContratosArray.push({id_contrato: this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato})
        this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato = ''
      } else {
        this.exibeToasty("Você precisa selecionar um template antes de adicionar outro", "error")
      }
    },
    getNameTemplateContrato(id_contrato){
      if (id_contrato) {
        const contrato = this.fastSecretariaContratos.find(c => c.id_contrato === id_contrato) 
            return contrato.nome_contrato ? contrato.nome_contrato : null;
      }
    },
    excluiTemplateContrato(){
      if(this.fastSecretariaContratosArray.length > 0){
        this.fastSecretariaContratosArray.pop()
      }
    },
    adicionaTemplateInscricao(){
      if(this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao){
        this.fastSecretariaInscricaoArray.push({id_secretaria_inscricao: this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao})
        this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao = ''
      } else {
        this.exibeToasty("Você precisa selecionar um template antes de adicionar outro", "error")
      }
    },
    getNameTemplateInscricao(id_inscricao){
      if (id_inscricao) {
        const inscricao = this.fastSecretariaInscricoes.find(i => i.id_secretaria_inscricao === id_inscricao) 
            return inscricao.nome_template ? inscricao.nome_template : null;
      }
    },
    excluiTemplateInscricao(){
      if(this.fastSecretariaInscricaoArray.length > 0){
        this.fastSecretariaInscricaoArray.pop()
      }
    },
    getNameTipoDocumento(id_tipo){
      if (id_tipo){
        const tipo = this.fastSecretariaTipos.find(i => i.id_assinatura_documento_tipo === id_tipo) 
            return tipo.nome_assinatura_documento_tipo ? tipo.nome_assinatura_documento_tipo : null;
      }
    },
    getNameCurso(id_curso){
      if (id_curso) {
        const curso = this.fastSecretariaCursos.find(c => c.id_secretaria_curso === id_curso) 
            return curso.nome_curso ? curso.nome_curso : null;
      }
    },
    getNameGateway(id_gateway){
      if (id_gateway){
        const gateway = this.fastSecretariaGateways.find(g => g.id_assinatura_gateway === id_gateway) 
            return gateway.nome_gateway ? gateway.nome_gateway : null;
      }
    },
    async criarTemplateComposicao() {
      this.fastSecretariaComposicoesCarregando = true
      let fastAssinaturaMatriculaTemplateContrato;
      if(this.fastSecretariaContratosArray.length >= 2) {
        fastAssinaturaMatriculaTemplateContrato = this.fastSecretariaContratosArray
      } else if (this.fastSecretariaContratosArray.length <= 1 && this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato) {
        fastAssinaturaMatriculaTemplateContrato = [{id_contrato: this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato}]
      } else {
        fastAssinaturaMatriculaTemplateContrato = null
      }
      let fastAssinaturaMatriculaTemplateInscricao;
      if(this.fastSecretariaInscricaoArray.length >= 2) {
        fastAssinaturaMatriculaTemplateInscricao = this.fastSecretariaInscricaoArray
      } else if (this.fastSecretariaInscricaoArray.length <= 1 && this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao) {
        fastAssinaturaMatriculaTemplateInscricao = [{id_secretaria_inscricao: this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao}]
      } else {
        fastAssinaturaMatriculaTemplateInscricao = null
      }
      this.fastSecretariaInscricaoArray.length ? this.fastSecretariaInscricaoArray : [{id_secretaria_inscricao: this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao}]      
      let obj = {
                  id_plataforma: this.$route.params.id_plataforma,
                  nome_template: this.fastSecretariaTemplate.nome_template,
                  id_assinatura_gateway: this.fastSecretariaTemplate.id_assinatura_gateway ? this.fastSecretariaTemplate.id_assinatura_gateway : null,
                  ativo: true,
                  aluno_assina: this.fastSecretariaTemplate.aluno_assina,
                  responsavel_aluno_assina: this.fastSecretariaTemplate.responsavel_aluno_assina,
                  responsavel_gateway_assina: this.fastSecretariaTemplate.responsavel_gateway_assina,
                  id_secretaria_curso: this.fastSecretariaTemplate.id_secretaria_curso ? this.fastSecretariaTemplate.id_secretaria_curso : null,
                  fast_assinatura_matricula_template_contrato: fastAssinaturaMatriculaTemplateContrato,
                  fast_assinatura_matricula_template_inscricao: fastAssinaturaMatriculaTemplateInscricao,
                }
      console.log("obj ", obj)
      let erros = []
      if(!obj.nome_template) erros.push("Você deve inserir o nome do template de composição")
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        this.promiseInserirFastApi(obj, "fast_assinatura_matricula_template")
        .then((res) => {
          if(res.success){
            // console.log("criarTemplateComposicao", res)
            this.exibeToasty("Composição criada com sucesso", "success");
            this.hideModal("modalCriarTemplate");
            this.getTemplatesComposicao();
            this.fastSecretariaComposicoesCarregando = false
          } else {
            console.log(res.error)
            console.log(res.description)
            this.exibeToasty(res.error, "error");
            this.exibeToasty(res.description, "error")
            this.fastSecretariaComposicoesCarregando = false
          }
        }).catch((e) => {
          console.log(e)
          this.exibeToasty("Erro ao criar requisito", "error");
          this.fastSecretariaComposicoesCarregando = false
  
        });
      }
    },
    exibeModalEditarTemplate(template) {
      this.fastSecretariaTemplate = template;
      this.fastSecretariaContratosArray = template.fast_assinatura_matricula_template_contrato
      this.fastSecretariaInscricaoArray = template.fast_assinatura_matricula_template_inscricao
      this.showModal("modalEditarTemplate");
    },
    async atualizaTemplate() {
      this.fastSecretariaComposicoesCarregando = true
      let obj = {
                  id_assinatura_matricula_template: this.fastSecretariaTemplate.id_assinatura_matricula_template,
                  id_plataforma: this.$route.params.id_plataforma,
                  nome_template: this.fastSecretariaTemplate.nome_template,
                  id_assinatura_gateway: this.fastSecretariaTemplate.id_assinatura_gateway ? this.fastSecretariaTemplate.id_assinatura_gateway : null,
                  ativo: this.fastSecretariaTemplate.ativo,
                  aluno_assina: this.fastSecretariaTemplate.aluno_assina,
                  responsavel_aluno_assina: this.fastSecretariaTemplate.responsavel_aluno_assina,
                  responsavel_gateway_assina: this.fastSecretariaTemplate.responsavel_gateway_assina,
                  id_secretaria_curso: this.fastSecretariaTemplate.id_secretaria_curso ? this.fastSecretariaTemplate.id_secretaria_curso : null,
                }
      // console.log("obj ", obj)
      let erros = []
      if(!obj.nome_template) erros.push("O nome do template de composição não pode estar vazio")
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        this.promiseAtualizarFastApi(obj, "fast_assinatura_matricula_template")
        .then((res) => {
          if(res.success) {
            // console.log("alterarTemplateComposição", res)
            this.exibeToasty("Composição alterada com sucesso", "success");
            this.hideModal("modalEditarTemplate");
            this.getTemplatesComposicao();
            this.fastSecretariaComposicoesCarregando = false
          } else {
            console.log(res.error)
            console.log(res.description)
            this.exibeToasty(res.error, "error");
            this.exibeToasty(res.description, "error")
            this.fastSecretariaComposicoesCarregando = false
          }
        }).catch((e) => {
          console.log(e)
          this.exibeToasty("Erro ao criar requisito", "error");
          this.fastSecretariaComposicoesCarregando = false
  
        });
      }
    },
    atualizaTemplateAdicionaContrato(){
      this.fastSecretariaComposicoesCarregando = true
      let obj = {
                  id_assinatura_matricula_template: this.fastSecretariaTemplate.id_assinatura_matricula_template,
                  id_plataforma: this.$route.params.id_plataforma,
                  id_contrato: this.fastSecretariaTemplate.fast_assinatura_matricula_template_contrato.id_contrato
                }
      // console.log("obj ", obj)
      let erros = []
      if(!obj.id_contrato) erros.push("Um template de contrato deve ser selecionado")
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        return new Promise(async (resolve, reject) => {
          this.promisePostFastApi(obj, "api/fast_assinatura_matricula_template/insere_contrato")
          .then((res) => {
            if(res.success){
              // console.log("AdicionaContrato", res)
              this.exibeToasty("Composição alterada com sucesso", "success")
              this.hideModal('modalAtualizarTemplateContrato')
              this.hideModal("modalEditarTemplate");
              this.getTemplatesComposicao();
              this.fastSecretariaOpcaoAdicionaContrato = ""
              this.fastSecretariaOpcaoRemoveContrato = ""
              resolve(res)
              this.fastSecretariaComposicoesCarregando = false
            } else {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error, "error");
              this.exibeToasty(res.description, "error")
              reject(res)
              this.fastSecretariaComposicoesCarregando = false
            }
          }).catch((e) => {
            console.log(e)
            this.exibeToasty("Erro ao criar requisito", "error")
            reject(e)
            this.fastSecretariaComposicoesCarregando = false
          });
        })
      }
    },
    atualizaTemplateRemoveContrato(id_contrato){
      this.fastSecretariaComposicoesCarregando = true
      let obj = {
                  id_assinatura_matricula_template_contrato: id_contrato,
                  id_assinatura_matricula_template: this.fastSecretariaTemplate.id_assinatura_matricula_template,
                  id_plataforma: this.$route.params.id_plataforma
                }
      // console.log("obj ", obj)
      let erros = []
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        return new Promise(async (resolve, reject) => {
          this.promisePostFastApi(obj, "api/fast_assinatura_matricula_template/exclui_contrato")
          .then((res) => {
            if (res.success){
              // console.log("RemoveContrato", res)
              this.exibeToasty("Composição alterada com sucesso", "success")
              this.hideModal('modalAtualizarTemplateContrato')
              this.hideModal("modalEditarTemplate");
              this.fastSecretariaOpcaoAdicionaContrato = ""
              this.fastSecretariaOpcaoRemoveContrato = ""
              this.getTemplatesComposicao();
              resolve(res)
              this.fastSecretariaComposicoesCarregando = false
            } else {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error, "error");
              this.exibeToasty(res.description, "error")
              reject(res)
              this.fastSecretariaComposicoesCarregando = false
            }
          }).catch((e) => {
            console.log(e)
            this.exibeToasty("Erro ao criar requisito", "error")
            reject(e)
            this.fastSecretariaComposicoesCarregando = false
          });
        })
      }
    },
    atualizaTemplateAdicionaInscricao(){
      this.fastSecretariaComposicoesCarregando = true
      let obj = {
                  id_assinatura_matricula_template: this.fastSecretariaTemplate.id_assinatura_matricula_template,
                  id_plataforma: this.$route.params.id_plataforma,
                  id_secretaria_inscricao: this.fastSecretariaTemplate.fast_assinatura_matricula_template_inscricao.id_secretaria_inscricao
                }
      // console.log("obj ", obj)
      let erros = []
      if(!obj.id_secretaria_inscricao) erros.push("Um template de contrato deve ser selecionado")
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        return new Promise(async (resolve, reject) => {
          this.promisePostFastApi(obj, "api/fast_assinatura_matricula_template/insere_inscricao")
          .then((res) => {
            if (res.success) {
              // console.log("AdicionaInscricao", res)
              this.exibeToasty("Composição alterada com sucesso", "success")
              this.hideModal('modalAtualizarTemplateInscricao')
              this.hideModal("modalEditarTemplate");
              this.getTemplatesComposicao();
              this.fastSecretariaOpcaoAdicionaInscricao = ""
              this.fastSecretariaOpcaoRemoveInscricao = ""
              resolve(res)
              this.fastSecretariaComposicoesCarregando = false
            } else {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error, "error");
              this.exibeToasty(res.description, "error")
              reject(res)
              this.fastSecretariaComposicoesCarregando = false
            }
          }).catch((e) => {
            console.log(e)
            this.exibeToasty("Erro ao criar requisito", "error")
            reject(e)
            this.fastSecretariaComposicoesCarregando = false
          });
        })
      }
    },
    atualizaTemplateRemoveInscricao(id_inscricao){
      this.fastSecretariaComposicoesCarregando = true
      let obj = {
                  id_assinatura_matricula_template_inscricao: id_inscricao,
                  id_assinatura_matricula_template: this.fastSecretariaTemplate.id_assinatura_matricula_template,
                  id_plataforma: this.$route.params.id_plataforma
                }
      // console.log("obj ", obj)
      let erros = []
      if (erros.length) {
        erros.forEach(e => {this.exibeToasty(e, "error")});
      } else {
        return new Promise(async (resolve, reject) => {
          this.promisePostFastApi(obj, "api/fast_assinatura_matricula_template/exclui_inscricao")
          .then((res) => {
            if (res.success) {
              // console.log("RemoveInscricao", res)
              this.exibeToasty("Composição alterada com sucesso", "success")
              this.hideModal('modalAtualizarTemplateInscricao')
              this.hideModal("modalEditarTemplate");
              this.fastSecretariaOpcaoAdicionaInscricao = ""
              this.fastSecretariaOpcaoRemoveInscricao = ""
              this.getTemplatesComposicao();
              resolve(res)
              this.fastSecretariaComposicoesCarregando = false
            } else {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error, "error");
              this.exibeToasty(res.description, "error")
              reject(res)
              this.fastSecretariaComposicoesCarregando = false
            }
          }).catch((e) => {
            console.log(e)
            this.exibeToasty("Erro ao criar requisito", "error")
            reject(e)
            this.fastSecretariaComposicoesCarregando = false
          });
        })
      }
    },
    exibeModalAtualizarTemplateContrato(){
      this.showModal('modalAtualizarTemplateContrato')
    },
    exibeModalAtualizarTemplateInscricao(){
      this.showModal('modalAtualizarTemplateInscricao')
    },
    avisoTemplateAtivo(){
      if(!this.fastSecretariaTemplate.ativo){
        this.exibeToasty("Ao inativar o template, este não será exibido na lista principal, ficando acessível em 'todos os templates'.", "info")
      }
    },
    exibeTooltip(retorno_tooltip){
      switch (retorno_tooltip) {
        case 'contrato':
          return (
                '<p class="text-info"><b>Adicionar outros templates de contrato</b></p>' +
                "<p>Clique aqui caso queira adicionar mais de um template de contrato a esta composição.</p>"
              );
        case 'inscricao':
          return (
                '<p class="text-info"><b>Adicionar outros templates de inscrição</b></p>' +
                "<p>Clique aqui caso queira adicionar mais de um template de inscrição a esta composição.</p>"
              );
      }
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 300px;
  min-width: 300px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
